import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form,
    Input, message,
    Pagination,
    PaginationProps, Popconfirm,
    Space,
    Table,
    TableColumnsType,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {TableDataType} from "../../../../apis/types/sub/subscriber";
import {TableDataType as ChildTableDataType} from "../../../../apis/types/sub/subuser";
import {OptionRes} from "../../../../apis/types/common";
import subscriberApi from "../../../../apis/sub/subscriberApi";
import subuserApi from "../../../../apis/sub/subuser";
import SubscriberForm from "./subscriberForm";
import propertiesApi from "../../../../apis/sys/properties";
import SubUserForm from "../subuser/subUserForm";
import Details from "../../public/details/details";
import dayjs from "dayjs";

const {RangePicker} = DatePicker;
const SubscriberContent = () => {

    //进入页面先查询数据
    useEffect(() => {
        // handleQuery();
        queryParams();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<TableDataType[]>([]);
    const [childList, setChildList] = useState<{ [key: number]: ChildTableDataType }>({});
    const [idList, setIdList] = useState<Number[]>([]);
        const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [type, setType] = useState(false)
    const [childOpen, setChildOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [childRowData, setChildRowData] = useState<ChildTableDataType>({} as ChildTableDataType)

    // 下拉列表选项
    const [accessMethodOption, setAccessMethodOption] = useState<OptionRes[]>([])
    const [statusCdOption, setStatusCdOption] = useState<OptionRes[]>([])
    const [userStatusCdOption, setUserStatusCdOption] = useState<OptionRes[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);


    // 查询参数
    function queryParams() {
        propertiesApi.optinos('DM_Subscriber_Status').then((res) => {
            if (res) {

                setStatusCdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_User_Status').then((res) => {
            if (res) {
                setUserStatusCdOption(res.data)
            }
        })
    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            beginRegistTime: body.beginRegistTime ? dayjs(body.beginRegistTime).format('YYYY-MM-DD') : null,
            endRegistTime: body.endRegistTime ? dayjs(body.endRegistTime).format('YYYY-MM-DD') : null,
        }
        let res = subscriberApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    function handleChildQuery(id: number) {
        let body = form.getFieldsValue();
        body = {...body, "subscriberId": id}
        let res = subuserApi.pageData(body, pagination);
        res.then((result) => {
            // setTotal(result.data.total)
            setChildList(Object.assign({}, childList, {[id]: result.data.records}));

        })

    }


    //处理删除
    const handleDelete = () => {
        subscriberApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            beginRegistTime: body.beginRegistTime ? dayjs(body.beginRegistTime).format('YYYY-MM-DD') : null,
            endRegistTime: body.endRegistTime ? dayjs(body.endRegistTime).format('YYYY-MM-DD') : null,
        }
        subscriberApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                    setType(false)
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '会员编码',
            dataIndex: 'subscriberCode',
            key: 'subscriberCode',
        },
        {
            title: '会员昵称',
            dataIndex: 'nickName',
            key: 'nickName',
        },
        {
            title: '会员头像',
            dataIndex: 'profilePhoto',
            key: 'profilePhoto',
        }
        ,
        {
            title: '手机号码',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'EMAIL',
            dataIndex: 'emailAddr',
            key: 'emailAddr',
            width: 150
        },
        {
            title: '微信号',
            dataIndex: 'webchatNo',
            key: 'webchatNo',
        },
        {
            title: '会员类型',
            dataIndex: 'subscriberTypeDesc',
            key: 'subscriberTypeDesc',
        },
        {
            title: '会员等级',
            dataIndex: 'subscriberGradeDesc',
            key: 'subscriberGradeDesc',
        },
        {
            title: '会员信用等级',
            dataIndex: 'creditGradeDesc',
            key: 'creditGradeDesc',
        },
        {
            title: '发展渠道',
            dataIndex: 'channelName',
            key: 'channelName',
        },
        {
            title: '归属类型',
            dataIndex: 'ownerTypeDesc',
            key: 'ownerTypeDesc',
        }
        ,
        {
            title: '归属对象',
            dataIndex: 'ownerObjectId',
            key: 'ownerObjectId',
        }
        ,
        {
            title: '注册时间',
            dataIndex: 'registTime',
            key: 'registTime',
            ellipsis: true
        }
        ,
        {
            title: '用户状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
        }
        ,
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    interface ExpandedDataType {
        key: React.Key;
        date: string;
        name: string;
        upgradeNum: string;
    }

    const expandedRowRender = (record: TableDataType) => {


        console.log("expandedRowRender", record.id)
        const columns: TableColumnsType<ChildTableDataType> = [
            {
                title: '操作',
                dataIndex: 'operation',
                render: (_: any, record: ChildTableDataType) => {
                    return <Typography.Link onClick={() => {
                        setChildOpen(true)
                        setModel('edit')
                        setChildRowData(record);
                        setType(false)
                    }}>
                        编辑
                    </Typography.Link>
                },
            },
            {title: 'id', dataIndex: 'id', key: 'id'},
            {title: '会员编码', dataIndex: 'subscriberCode', key: 'subscriberCode'},
            {title: '主账号标识', dataIndex: 'primaryMarkDesc', key: 'primaryMarkDesc'},
            {title: '登录账号', dataIndex: 'acctNo', key: 'acctNo'},
            {title: '微信OpenId', dataIndex: 'wxOpenid', key: 'wxOpenid'},
            {title: '微信UnionId', dataIndex: 'wxUnionid', key: 'wxUnionid'},
            {title: 'qq OpenId', dataIndex: 'qqOpenid', key: 'qqOpenid'},
            {title: 'QQ UnionId', dataIndex: 'qqUnionid', key: 'qqUnionid'},
            {title: '注册时间', dataIndex: 'registTime', key: 'registTime'},
            {title: '状态', dataIndex: 'statusdesc', key: 'statusdesc'},
            {title: '备注', dataIndex: 'notes', key: 'notes'},

        ];

        return <Table columns={columns} dataSource={childList[record.id]} pagination={false}
                      rowKey={record => record.id}/>;
    };
    const onExpand = (expanded: Boolean, record: TableDataType) => {
        if (expanded === false) {
            setChildList(Object.assign({}, childList, {[record.id]: []}));
        } else {
            handleChildQuery(record.id);
        }
    }
    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="subscriberCode" label="会员编码">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="phoneNumber" label="会员手机号">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="nickName" label="会员昵称">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="acctNo" label="登录账号">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="channelName" label="发展渠道">
                        <Input/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="registTime" label="注册时间">
                        <RangePicker
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginRegistTime", null);
                                    form.setFieldValue("endRegistTime", null);
                                } else {
                                    if (Array.isArray(value)) {
                                        const valueArray = value;
                                        let date = Array.from(value);
                                        if (date.length == 2) {
                                            form.setFieldValue("beginRegistTime", date[0]);
                                            form.setFieldValue("endRegistTime", date[1]);
                                        }
                                        console.log('onOk: ', date);
                                    }
                                }
                            }}></RangePicker>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="beginRegistTime" hidden={true}></Form.Item>
                    <Form.Item style={{margin: 5}} name="endRegistTime" hidden={true}></Form.Item>
                    <Form.Item style={{margin: 5}}>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={()=>{
                                const body={};
                                subscriberApi.pageData(body,pagination).then((res)=>{
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                    }
                                })
                            }}>重置</Button>
                            <Popconfirm
                                title="是否确认导出筛选的数据？"
                                onConfirm={confirmDerive}
                                onCancel={cancelDerive}
                                okText="是"
                                cancelText="否"
                            >
                                <Button type="primary" danger style={{marginRight: '25px'}}>
                                    导出
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                   <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} dataSource={list}
                       columns={columns} rowKey={record => record.id} pagination={false}
                       expandable={{expandedRowRender, onExpand: onExpand}}
                />
            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <SubscriberForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                statusOption={statusCdOption}
                type={type}></SubscriberForm>
            {/*新增和编辑的form表单，子组件*/}
            <SubUserForm
                refresh={() => {
                    //设置表单不展示
                    setChildOpen(false);
                    handleChildQuery(childRowData.subscriberId)
                    setChildRowData({} as ChildTableDataType)
                }}
                record={childRowData}
                open={childOpen}
                closeOpen={() => {
                    setChildOpen(false)
                    setChildRowData({} as ChildTableDataType)
                }}
                statusOption={userStatusCdOption}
                type={type}></SubUserForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default SubscriberContent;