const baseUrl = "/res/iptables";
/** 获取列表-参数 */
export interface PageParam {
    instanceId:number;
    gatewayId:number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    gatewayName: string;
    externalTcpPort: string;
    externalUdpPort: string;
    instanceCode: number;
    hostIp: string;
    tcpPort: string;
    udpPort: string;
    statusDesc: string;
    notes: string;
}

export  {baseUrl};