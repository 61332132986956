import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Input, Modal, Row, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/res/idc";
import idcApi from "../../../../apis/res/idc";
import {OptionRes} from "../../../../apis/types/common";
import IdcConfigFrom from "../idcConfig/idcConfigForm";
import TextArea from "antd/es/input/TextArea";
import {filterOption} from "../../../../apis/common";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;
    idcTypeCds: OptionRes[];
    statusCds: OptionRes[];
    suppliers: OptionRes[];
    areaOptions: OptionRes[];
    releasedStatusOp: OptionRes[];
    model: String;
}


const IdcFrom: React.FC<StaffFromProps> = ({
                                               type,
                                               refresh,
                                               record,
                                               open,
                                               closeOpen,
                                               idcTypeCds,
                                               suppliers,
                                               statusCds,
                                               areaOptions,
                                               releasedStatusOp,
                                               model
                                           }) => {
    const [form] = Form.useForm();
    const [idcId, setIdcId] = useState<number>(0)

    // 编辑的时候回显数据
    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        console.log("useEffecttt", record)
        form.setFieldsValue({})
        form.resetFields()
        console.log("form",form.getFieldsValue())
        if (open&&record&&!type) {
            form.setFieldsValue({
                ...record,
                areaId: "" + record.areaId,
                releasedStatus: "" + record.releasedStatus,
            });
        }
        setIdcId(record.id);
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        idcApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        idcApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            width={1200}
            cancelText={"取消"}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // initialValues={record}
                onFinish={handleOK}
            >

                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={6}>
                        <Form.Item name="idcName" label="机房名称"
                                   rules={[{required: true, message: "机房名称不能为空"}, {max: 128, message: "机房名称长度不能超过128位"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="idcCode" label="机房编码"
                                   rules={[{required: true, message: "机房编码不能为空"}, {max: 32, message: "机房编码长度不能超过32位"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="areaId" label="所在区域"
                                   rules={[{required: true, message: "所在区域不能为空"}]}>
                            <Select
                                showSearch
                                filterOption={filterOption}
                                allowClear={true}
                                style={{width: 192}}
                                options={areaOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="idcAddr" label="机房地址"
                                   rules={[{required: true, message: "机房地址不能为空"}, {max: 255, message: "机房地址长度不能超过255位"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="idcTypeCd" label="机房类型"
                                   rules={[{required: true, message: "机房类型不能为空"}]}>
                            <Select
                                allowClear={true}
                                style={{width: 192}}
                                options={idcTypeCds}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="supplierId" label="供应商"
                                   rules={[{required: true, message: "供应商不能为空"}]}>
                            <Select
                                allowClear={true}
                                style={{width: 192}}
                                options={suppliers}
                            />
                        </Form.Item>
                    </Col>
                    {/*<Col span={6} hidden={model == 'create'}>*/}
                        <Form.Item hidden={model == 'create'} name="releasedStatus" label="释放状态" rules={[{required: model !== 'create', message: "释放态不能为空"}]}>
                            <Select
                                style={{width: 200}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={releasedStatusOp}
                            />
                        </Form.Item>
                    {/*</Col>*/}
                    <Col span={6}>
                        <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                            <Select
                                allowClear={true}
                                style={{width: 192}}
                                options={statusCds}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="notes" label="备注"
                                   rules={[ {max: 255, message: "备注长度不能超过255位"}]}>
                            <TextArea rows={1} style={{width: 192}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{textAlign: "center"}}>
                    <Form.Item style={{display: "inline-block"}}>
                        <Button type="primary" htmlType="submit">
                            提交基础信息
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            <div hidden={type}>
                <div>
                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                    <h3>机房配置管理</h3>
                    <IdcConfigFrom
                        refresh={() => {
                            //重新调用查询的接口
                            refresh();
                        }}
                        idcId={idcId}
                    ></IdcConfigFrom>
                </div>
            </div>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default IdcFrom;



