import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/res/instance";
import commonApi from "../commonApi";

const instanceApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.idcId) {
                url += 'idcId=' + encodeURIComponent(body.idcId) + "&";
            }
            if (body.statusCd) {
                url += 'statusCd=' + encodeURIComponent(body.statusCd) + "&";
            }
            if (body.subscriberCode) {
                url += 'subscriberCode=' + encodeURIComponent(body.subscriberCode) + "&";
            }
            if (body.phoneNumber) {
                url += 'phoneNumber=' + encodeURIComponent(body.phoneNumber) + "&";
            }
            if (body.serverCodeStr) {
                url += 'serverCodeStr=' + encodeURIComponent(body.serverCodeStr) + "&";
            }
            if (body.hostCodeStr) {
                url += 'hostCodeStr=' + encodeURIComponent(body.hostCodeStr) + "&";
            }
            if (body.instanceCodeStr) {
                url += 'instanceCodeStr=' + encodeURIComponent(body.instanceCodeStr) + "&";
            }
            if (body.impartStatus) {
                url += 'impartStatus=' + encodeURIComponent(body.impartStatus) + "&";
            }
            if (body.activateMark) {
                url += 'activateMark=' + encodeURIComponent(body.activateMark) + "&";
            }
            if (body.onlineMark) {
                url += 'onlineMark=' + encodeURIComponent(body.onlineMark) + "&";
            }
            if (body.handleMark) {
                url += 'handleMark=' + encodeURIComponent(body.handleMark) + "&";
            }
            if (body.faultMark) {
                url += 'faultMark=' + encodeURIComponent(body.faultMark) + "&";
            }
            if (body.enableMark) {
                url += 'enableMark=' + encodeURIComponent(body.enableMark) + "&";
            }
            if (body.faultTypeCd) {
                url += 'faultTypeCd=' + encodeURIComponent(body.faultTypeCd) + "&";
            }
            if (body.instanceGradeCd) {
                url += 'instanceGradeCd=' + encodeURIComponent(body.instanceGradeCd) + "&";
            }
            if (body.instanceSn) {
                url += 'instanceSn=' + encodeURIComponent(body.instanceSn) + "&";
            }
            if (body.instanceIp) {
                url += 'instanceIp=' + encodeURIComponent(body.instanceIp) + "&";
            }
            if (body.tagName) {
                url += 'tagName=' + encodeURIComponent(body.tagName) + "&";
            }
            if (body.beginSubsTime) {
                url += 'beginSubsTime=' + body.beginSubsTime + "&";
            }
            if (body.endSubsTime) {
                url += 'endSubsTime=' + body.endSubsTime + "&";
            }
            if (body.beginControlledTime) {
                url += 'beginControlledTime=' + body.beginControlledTime + "&";
            }
            if (body.endControlledTime) {
                url += 'endControlledTime=' + body.endControlledTime + "&";
            }
            if (body.beginExpTime) {
                url += 'beginExpTime=' + body.beginExpTime + "&";
            }
            if (body.endExpTime) {
                url += 'endExpTime=' + body.endExpTime + "&";
            }
            if (body.startHostIp) {
                url += 'startHostIp=' + encodeURIComponent(body.startHostIp) + "&";
            }
            if (body.endHostIp) {
                url += 'endHostIp=' + encodeURIComponent(body.endHostIp) + "&";
            }
            if (body.rootStatus) {
                url += 'rootStatus=' + encodeURIComponent(body.rootStatus) + "&";
            }

            if (body.notes) {
                url += 'statusCd=' + body.notes + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {

        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    batchCreate(body: any) {
        return request.post(baseUrl + "/batch/save", body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    optinos() {
        let url = baseUrl;
        url += "/options"
        return request.get<OptionRes[]>(url)
    },
    //重启云手机
    rebootIns(idList: Number[]) {
        let url = baseUrl + "/rebootIns";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    //一键新机
    initIns(idList: Number[]) {
        let url = baseUrl + "/initIns";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    //解绑
    unbind(idList: Number[]) {
        let url = baseUrl + "/unbind";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    //绑定
    bind(body: any) {
        let url = baseUrl + "/bind";
        return request.post(url,body);

    },
    //加时长
    addTime(body: any) {
        let url = baseUrl + "/addTime";
        return request.post(url,body);
    },

    // 回收的云手机重新上架
    reshelve(body: any) {
        let url = baseUrl + "/reshelve";
        // 创建 FormData 对象
        const formData = new FormData();

        // 添加 id 参数到 FormData
        formData.append('ids', body);

        return request.postForm(url,formData);
    },

    // 一键新机
    insInit(body: any) {
        let url = baseUrl + "/ctr/insInit";
        return request.post(url, body)
    },

    // 云手机截屏
    screenshot(body: any) {
        let url = baseUrl + "/ctr/screenshot";
        return request.post(url, body)
    },
    //启用
    enable(idList: Number[]) {
        let url = baseUrl+"/enable";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    //禁用
    disable(idList: Number[]) {
        let url = baseUrl+"/disable";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"云手机列表",body)
    },
}

export default instanceApi



