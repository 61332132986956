import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input, message,
    Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
} from 'antd';
import {ColumnsType} from "antd/es/table";
import ServiceForm from "./serviceForm";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import propertiesApi from "../../../../apis/sys/properties";
import cabinetApi from "../../../../apis/res/cabinet";
import idcApi from "../../../../apis/res/idc";
import supplierApi from "../../../../apis/res/supplier";
import serverModelApi from "../../../../apis/res/serverModel";
import serviceApi from "../../../../apis/res/server";
import {TableDataType} from "../../../../apis/types/res/server";
import hostVirtualApi from "../../../../apis/res/hostVirtual";
import gatewayApi from "../../../../apis/res/gateway";
import Details from "../../public/details/details";
import manageNodeApi from "../../../../apis/res/manageNode";


const ServiceContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos("DM_Server_Type").then((res) => {
            if (res) {

                setServerTypeOption(res.data)
            }
        })


        propertiesApi.optinos("DM_Faoult_Type").then((res) => {
            if (res) {

                setFaultTypeOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Server_Status").then((res) => {
            if (res) {

                setStatusOption(res.data)
            }
        })

        serverModelApi.options().then((res) => {
            if (res) {

                setServerModelOption(res.data)
            }
        })
        idcApi.optinos().then((res) => {
            if (res) {

                setIdcOption(res.data)
            }
        })
        cabinetApi.optinos().then((res) => {
            if (res) {

                setCabinetOption(res.data)
            }
        })
        supplierApi.optinos().then((res) => {
            if (res) {

                setSupplierOption(res.data)
            }
        })
        hostVirtualApi.optinos().then((res) => {
            if (res) {

                setVirtualOption(res.data)
            }
        })
        gatewayApi.optinos().then((res) => {
            if (res) {

                setGateWayOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Instance_Grade').then((res) => {
            if (res) {

                setInstanceGradeCds(res.data)
            }
        })
        manageNodeApi.options().then((res)=>{
            if (res){
                setManageNodeOptions(res.data);
            }
        })

    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [openBatch, setOpenBatch] = useState(false)
    const [openList, setOpenList] = useState(false)
    const [openFault, setOpenFault] = useState(false)
    const [openCreateVm, setCreateVmOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [serverTypeOption, setServerTypeOption] = useState<OptionRes[]>([])
    const [faultTypeOption, setFaultTypeOption] = useState<OptionRes[]>([])
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [serverModelOption, setServerModelOption] = useState<OptionRes[]>([])
    const [idcOption, setIdcOption] = useState<OptionRes[]>([])
    const [cabinetOption, setCabinetOption] = useState<OptionRes[]>([])
    const [supplierOption, setSupplierOption] = useState<OptionRes[]>([])
    const [gateWayOption, setGateWayOption] = useState<OptionRes[]>([])
    const [manageNodeOptions, setManageNodeOptions] = useState<OptionRes[]>([])
    const [virtualOption, setVirtualOption] = useState<OptionRes[]>([])
    const [openCheckIds, setOpenCheckIds] = useState(false);
    const [openDoubleCheck, setDoubleCheck] = useState(false);
    //定义操作类型：1-上架，2-下架，3-上电，4-下电，5-故障标记，6-出售。7-故障恢复，8-生成vm数据
    const [operate, setOperate] = useState<Number>();
    const [instanceGradeCds, setInstanceGradeCds] = useState<OptionRes[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = serviceApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        serviceApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const width = 100;

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        serviceApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     render: (_: any, record: TableDataType) => {
        //         return (
        //             <Typography.Link onClick={() => {
        //                 setOpen(true);
        //                 setRowData(record);
        //             }}>
        //                 编辑
        //             </Typography.Link>
        //         )
        //     },
        //     width: width,
        //
        // },
        {
            title: '服务器编码',
            dataIndex: 'serverCode',
            key: 'serverCode',
            width: 130,
            ellipsis: true,


        },
        {
            width: 130,
            title: '服务器类型',
            dataIndex: 'serverTypeDesc',
            key: 'serverTypeDesc',
            ellipsis: true,

        },
        {
            width: 130,

            title: '服务器型号',
            dataIndex: 'serverModelName',
            key: 'serverModelName',
            ellipsis: true,

        },


        // {
        //     width: width,
        //
        //     title: '生产批次号',
        //     dataIndex: 'productBatch',
        //     key: 'productBatch',
        // },
        {
            width: 120,

            title: 'BMC主控芯片',
            dataIndex: 'bmcChipTypeDesc',
            key: 'bmcChipTypeDesc',
            ellipsis: true,

        },
        {
            width: 120,

            title: 'BMC访问IP',
            dataIndex: 'bmcIp',
            key: 'bmcIp',
            ellipsis: true,

        },
        {
            width: 120,

            title: 'BMC访问端口',
            dataIndex: 'bmcPort',
            key: 'bmcPort',
            ellipsis: true,

        },
        {
            width: 140,

            title: 'BMC主控OS类型',
            dataIndex: 'bmcOsTypeDesc',
            key: 'bmcOsTypeDesc',
            ellipsis: true,

        },
        {
            width: 140,

            title: 'BMC主控OS版本',
            dataIndex: 'bmcOsVersion',
            key: 'bmcOsVersion',
            ellipsis: true,

        },
        {
            width: 120,

            title: '网络出口类型',
            dataIndex: 'adapterTypeDesc',
            key: 'adapterTypeDesc',
            ellipsis: true,

        },
        {
            width: 120,

            title: '网络出口数量',
            dataIndex: 'adapterQuantity',
            key: 'adapterQuantity',
            ellipsis: true,

        },
        {
            width: 130,

            title: '宿主机主控芯片',
            dataIndex: 'hostChipTypeDesc',
            key: 'hostChipTypeDesc',
            ellipsis: true,

        },
        // {
        //     width: width,
        //
        //     title: '宿主机PCB板版本',
        //     dataIndex: 'hostPcbVersion',
        //     key: 'hostPcbVersion',
        // },
        {
            width: 130,

            title: '宿主机OS类型',
            dataIndex: 'hostOsTypeDesc',
            key: 'hostOsTypeDesc',
            ellipsis: true,

        },
        {
            width: 130,

            title: '宿主机OS版本',
            dataIndex: 'hostOsVersion',
            key: 'hostOsVersion',
            ellipsis: true,

        },
        {
            width: 130,

            title: '宿主机运存',
            dataIndex: 'hostRamSize',
            key: 'hostRamSize',
            ellipsis: true,

        },
        {
            width: 130,

            title: '宿主机存储',
            dataIndex: 'hostRomSize',
            key: 'hostRomSize',
            ellipsis: true,

        },
        {
            width: 130,

            title: '宿主机数量',
            dataIndex: 'hostQuantity',
            key: 'hostQuantity',
            ellipsis: true,

        },
        {
            width: width,

            title: '机房',
            dataIndex: 'idcName',
            key: 'idcName',
            ellipsis: true,

        },
        {
            width: width,

            title: '机柜',
            dataIndex: 'cabinetCode',
            key: 'cabinetCode',
            ellipsis: true,

        },
        {
            width: width,

            title: '供应商',
            dataIndex: 'supplierName',
            key: 'supplierName',
            ellipsis: true,

        },
        {
            width: width,

            title: '故障标识',
            dataIndex: 'faultMarkDesc',
            key: 'faultMarkDesc',
            ellipsis: true,

        },
        // {
        //     width: width,
        //
        //     title: '故障类型',
        //     dataIndex: 'faultTypeDesc',
        //     key: 'faultTypeDesc',
        //     ellipsis:true,
        //
        // },
        {
            width: width,

            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis: true,

        },
        {
            width: width,

            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,

        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();
    //处理操作类型对应的值：//定义操作类型：1-上架，2-下架，3-上电，4-下电，5-故障标记，6-出售。7-故障恢复
    const handleOperate = () => {
        console.log("handleOperate", operate)
        if (operate === 1) {
            // 操作上架
            setOpenList(true)
        } else if (operate === 2) {
            // 处理为下架的情况
            serviceApi.deList(idList).then((res) => {
                setOperate(0)
                setDoubleCheck(false)
                handleQuery();
            })
        } else if (operate === 3) {
            // 处理为上电的情况
            serviceApi.powerOn(idList).then((res) => {
                setOperate(0)
                setDoubleCheck(false)
                handleQuery();
            })
        } else if (operate === 4) {
            // 处理为下电的情况
            serviceApi.powerOff(idList).then((res) => {
                setOperate(0)
                setDoubleCheck(false)
                handleQuery();
            })
        } else if (operate === 5) {
            // 处理为故障标记的情况
            setOpenFault(true)
        } else if (operate === 6) {
            // 处理为售出的情况
            serviceApi.sell(idList).then((res) => {
                setOperate(0)
                setDoubleCheck(false)
                handleQuery();
            })
        } else if (operate === 7) {
            // 处理为故障恢复的情况
            serviceApi.recover(idList).then((res) => {
                setOperate(0)
                setDoubleCheck(false)
                handleQuery();
            })
        } else if (operate === 8) {
            // 处理生成vm的情况
            setCreateVmOpen(true)
        }

    };
    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>
                {/*  <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>
                <Button type="primary" ghost onClick={() => {
                    setOpenBatch(true)
                }} style={{marginRight: '25px'}}>
                    批量新增
                </Button>
                <Button type="primary" ghost onClick={() => {

                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOperate(1)
                        setDoubleCheck(true)
                    }
                }} style={{marginRight: '25px'}}>
                    上架
                </Button>

                <Button type="primary" ghost onClick={() => {
                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOperate(2)
                        setDoubleCheck(true)
                    }
                }} style={{marginRight: '25px'}}>
                    下架
                </Button>

                <Button type="primary" ghost onClick={() => {
                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOperate(3)
                        setDoubleCheck(true)
                    }
                }} style={{marginRight: '25px'}}>
                    上电
                </Button>
                <Button type="primary" ghost onClick={() => {
                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOperate(4)
                        setDoubleCheck(true)
                    }
                }} style={{marginRight: '25px'}}>
                    下电
                </Button>
                <Button type="primary" ghost onClick={() => {
                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOperate(5)
                        setDoubleCheck(true)
                    }
                }} style={{marginRight: '25px'}}>
                    故障标记
                </Button>
                <Button type="primary" ghost onClick={() => {
                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOperate(7)
                        setDoubleCheck(true)
                    }
                }} style={{marginRight: '25px'}}>
                    故障恢复
                </Button>
                <Button type="primary" ghost onClick={() => {
                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOperate(6)
                        setDoubleCheck(true)
                    }
                }} style={{marginRight: '25px'}}>
                    售出
                </Button>
                <Button type="primary" ghost onClick={() => {
                    if (idList.length == 0) {
                        setOpenCheckIds(true)
                    } else {
                        setOperate(8)
                        setCreateVmOpen(true)
                    }
                }} style={{marginRight: '25px'}}>
                    生成vm
                </Button>*/}
                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="serverCode" label="服务器编码">
                        <Input/>
                    </Form.Item>

                    <Form.Item name="serverTypeCd" label="服务器类型">
                        <Select
                            style={{width: 150}}
                            showSearch

                            optionFilterProp="children"
                            allowClear

                            filterOption={filterOption}
                            options={serverTypeOption}
                        />
                    </Form.Item>
                    <Form.Item name="statusCd" label="状态">
                        <Select
                            style={{width: 150}}
                            showSearch

                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={statusOption}
                        />

                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                serviceApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} dataSource={list}
                    columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增和编辑的form表单，子组件*/}
            <ServiceForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                serverTypeOption={serverTypeOption}
                serverModelOption={serverModelOption}
                idcOption={idcOption}
                cabinetOption={cabinetOption}
                supplierOption={supplierOption}
                faultTypeOption={faultTypeOption}
                statusOption={statusOption}
                instanceGradeCdOptions={instanceGradeCds}
                gateWayOption={gateWayOption}
                manageNodeOptions={manageNodeOptions}
            ></ServiceForm>


            {/*判断操作的ids存在的弹窗*/}
            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIds} onOk={() => {
                setOpenCheckIds(false)
            }} onCancel={() => {
                setOpenCheckIds(false)
            }}>
                先选择要操作的数据
            </Modal>
            {/*二次确认的弹窗*/}
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openDoubleCheck} onOk={() => {
                handleOperate();
            }} onCancel={() => {
                setOperate(0)
                setDoubleCheck(false)
            }}>
                该操作会级联到其下的所有云手机，请确认是否继续。
            </Modal>
            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>
        </div>
    )
}


export default ServiceContent;