const baseUrl = "/res/idc";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    idcName:string,
    idcCode:string,
    areaId:string,
    idcTypeCd:string,
    idcAddr:string,
    releasedStatus: string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    idcName:string,
    idcCode:string,
    areaId:string,
    idcTypeCd:string,
    idcAddr:string,
    statusCd:string,
    releasedStatus: string,
    notes:string,
}



export  {baseUrl};

