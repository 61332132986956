import React, {useEffect, useState} from 'react';
import {AppstoreOutlined, MailOutlined, SettingOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Breadcrumb, Image, Layout, Tabs, Menu, message, Modal, theme} from 'antd';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {getToken, getUserInfo, removeToken, request} from "../../utils";
import loginApi from "../../apis/login";
import jwskImg from "../../img/jwsk.png"
import ChangePasswordFrom from "../view/sys/user/changePasswordFrom";
import TabsLayout from "./layout/tabsLayout";
import {StaffDate} from "../../apis/types/sys/staff";
import userApi from "../../apis/sys/user";

const {Header, Content, Footer, Sider} = Layout;


type MenuItem = Required<MenuProps>['items'][number];

function clickMenuItem() {
    window.scroll(0, 0)
}

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        onClick: clickMenuItem,
        key,
        icon,
        children,
        label,
        type,
        style: {margin: '1px'}, // 添加内边距样式
    } as MenuItem;
}

const items2: MenuProps['items'] = [

    getItem('系统配置', '/index/view/system', <SettingOutlined/>, [
        getItem('系统用户', '/index/view/sys/sec/staff'),
        getItem('组织管理', '/index/view/sys/organ'),
        getItem('角色管理', '/index/view/sys/sec/role'),
        getItem('功能管理', '/index/view/sys/sec/action'),
        getItem('字典管理', '/index/view/sys/prop/category'),
        getItem('配置管理', '/index/view/apply/sys/config'),
    ]),

    getItem('基础管理', '/index/view/basics', <SettingOutlined/>, [
        getItem('区域管理', '/index/view/pub/area'),
        getItem('供应商管理', '/index/view/res/supplier'),
        getItem('手机型号管理', '/index/view/res/phoneModel'),
        getItem('手机品牌管理', '/index/view/res/phoneBrands'),
        getItem('标签管理', '/index/view/pub/label'),
        getItem('渠道管理', '/index/view/pub/channel'),
        getItem('版本母包管理', '/index/view/pub/channel/channelParentVersion'),
        getItem('版本渠道包管理', '/index/view/pub/channel/channelVersion'),
        getItem('虚拟化配置管理', '/index/view/res/hostVirtual'),
        getItem('服务器型号信息', '/index/view/res/serverModel'),
    ]),

    getItem('云手机管理', '/index/view/equipment', <AppstoreOutlined/>, [
        getItem('机房信息管理', '/index/view/res/idc'),
        getItem('机柜信息管理', '/index/view/res/cabinet'),
        getItem('服务器管理', '/index/view/res/service'),
        getItem('宿主机管理', '/index/view/res/host'),
        getItem('云手机管理', '/index/view/res/instance'),
        getItem('产品配置管理', '/index/view/app/product/config'),
        getItem('网关信息管理', '/index/view/res/gateway'),
        getItem('管理节点管理', '/index/view/res/manageNode'),
        // getItem('MQ服务管理', '/index/view/res/proxy/mq'),
        getItem('文件服务管理', '/index/view/res/file/service'),
        getItem('云手机任务管理', '/index/view/res/InsTaskManage'),
        getItem('云手机授权管理', '/index/view/res/insGrant'),
        getItem('转让云手机管理', '/index/view/res/insTransfer'),
        getItem('宕机云手机查询', '/index/view/res/unStatusList'),
        getItem('iptables', '/index/view/res/iptables'),
    ]),

    getItem('会员管理', '/index/view/member', <MailOutlined/>, [
        getItem('会员管理', '/index/view/sub/subscriber'),
        getItem('用户文件管理', '/index/view/sub/file/upload'),
        getItem('消息功能', '/index/view/apply/opt/msg'),
        getItem('登录日志', '/index/view/sub/subLogin/subLogin'),
    ]),

    getItem('营销管理', '/index/view/marketing', <MailOutlined/>, [
        getItem('图片管理', '/index/view/pub/picture'),
        getItem('任务管理', '/index/6'),
        getItem('活动管理', '/index/view/mkt/act'),
        getItem('优惠券模版管理', '/index/view/mkt/coupon/template'),
        getItem('优惠券管理', '/index/view/mkt/coupon/coupon'),
        getItem('兑换码管理', '/index/view/marketing/redeemCode'),
        getItem('短信模板信息管理', '/index/view/opt/smsTemplate'),
        getItem('短信平台信息管理', '/index/view/opt/smsPlatform'),
        getItem('短信信息管理', '/index/view/opt/list'),
    ]),

    getItem('订阅管理', '/index/view/order', <MailOutlined/>, [
        getItem('产品管理', '/index/view/pdt/product'),
        getItem('商品管理', '/index/view/pdt/goods'),
        // getItem('升级商品管理', '/index/view/pdt/upgradeGoods'),
        getItem('订单管理', '/index/view/ord/order'),
        getItem('退款管理', '/index/view/cus/refund'),

    ]),

    getItem('应用市场', '/index/view/apply', <AppstoreOutlined/>, [
        getItem('应用管理', '/index/view/app/catalog/info'),
        // getItem('游戏管理', '/index/11'),
        // getItem('黑名单管理', '/index/12'),
        getItem('推荐应用实体管理', '/index/13'),
        getItem('应用套件管理', '/index/14'),
        getItem('apk安装认证管理', '/index/view/apply/apk/notAllowInstall'),
        getItem('系统隐藏apk管理', '/index/view/apply/apk/sysHidden'),
    ]),

    getItem('客服管理', '/index/view/customer/service', <MailOutlined/>, [
        getItem('故障工单管理', '/index/view/cus/fault'),
        getItem('客服管理', '/index/16'),
    ]),

    getItem('日志查看', '/index/view/log', <MailOutlined/>, [
        getItem('加时长日志', '/index/view/log/addtime'),
        getItem('运营后台操作日志', '/index/view/log/web/operation'),
    ]),


    {type: 'divider'},
];
/**
 * 通过key获取到名称
 * @param key
 */
export const findNameByKey = (key: string) => {
    const findItem = (items: MenuItem[], searchKey: string): string | null => {
        for (const item of items) {
            if (item.key === searchKey) {
                console.log("item", item, searchKey)
                return item.label;
            }
            if (item.children) {
                const nestedItem = findItem(item.children, searchKey);
                if (nestedItem) {
                    return nestedItem;
                }
            }
        }
        return null;
    };

    const name = findItem(items2, key);
    return name;
};
const CommonIndex: React.FC = () => {
    const [resultMenu, setResultMenu] = useState<MenuItem[]>([]);
    const [admin, setAdmin] = useState("");

    const {
        token: {colorBgContainer},
    } = theme.useToken();
    const navigate = useNavigate();
    console.log("token",getToken())

    // 通过usEeffect动态的去生成菜单
    useEffect(() => {
        if(!getToken()){
            navigate("/login")
        }
        let sys = request.get("sys/action/list/menu").then(async (result) => {
            if (result) {
                let resultJson = JSON.stringify(result);
                let responseMenu = JSON.parse(resultJson).data;
                console.log("responseMenu", responseMenu)
                const acctNo = getUserInfo()
                if (acctNo === "admin") {
                    setResultMenu(items2)
                } else {
                    let tempMenu = filterMenuItems(items2, responseMenu)

                    console.log("tempMenu", tempMenu)
                    setResultMenu(tempMenu)
                }
            }
        })

        userApi.getCurrentUser().then((reset) => {
            if (reset) {
                setUser(reset.data)
            }
        })
    }, []);


    function filterMenuItems(items: MenuItem[], responseMenu: string[]): MenuItem[] {
        const filteredItems: MenuItem[] = [];

        for (const item of items) {
            if (item.type === 'divider') {
                // 将分隔符项直接添加到结果数组中
                filteredItems.push(item);
            } else if (responseMenu.includes(item.key as string)) {
                // 检查菜单项的键是否存在于 responseMenu 数组中
                const filteredChildren: MenuItem[] = [];

                if (item.children) {
                    // 对子菜单项进行递归过滤
                    const filteredChildItems = filterMenuItems(item.children, responseMenu);
                    filteredChildren.push(...filteredChildItems);
                }
                if (item.children) {
                    // 创建一个带有过滤后子菜单项的新菜单项
                    const filteredItem: MenuItem = getItem(
                        item.label,
                        item.key,
                        item.icon,
                        filteredChildren,
                        item.type
                    );
                    // 将过滤后的菜单项添加到结果数组中
                    filteredItems.push(filteredItem);
                } else {
                    // 创建一个带有过滤后子菜单项的新菜单项
                    const filteredItem: MenuItem = getItem(
                        item.label,
                        item.key,
                        item.icon,
                        item.type
                    );

                    // 将过滤后的菜单项添加到结果数组中
                    filteredItems.push(filteredItem);
                }

            }
        }
        console.log("filteredItems", filteredItems)
        return filteredItems;
    }


    const onClick: MenuProps['onClick'] = (e) => {
        let key = e.key;
        navigate(key)

    };
    let location = useLocation();
    const selectKey = location.pathname;

    const [user, setUser] = useState<StaffDate>({} as StaffDate);
    const [open, setOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const items1: MenuProps['items'] = [
        {
            label: '个人中心',
            key: 'app3',
            onClick: () => {
                setIsModalOpen(true);
            },
        },
        {
            label: '修改密码',
            key: 'app2',
            onClick: () => {
                setOpen(true);
            },
        },
        {
            label: '退出登录',
            key: 'app1',
            onClick: () => {
                loginApi.loginOut().then((res) => {
                    if (res) {
                        message.success("退出成功")
                        removeToken();
                        navigate("/login")
                    }

                })
            },
            icon: <AppstoreOutlined/>,
        }

    ];

    return (
        <Layout style={{overflow: 'hidden'}}>
            <Header style={{display: 'flex', alignItems: 'center', backgroundColor: "#1B2534"}}>
                <img src={jwskImg} alt={""}/>
                <div className="demo-logo"/>
                <div style={{flex: 1}}/>
                <Menu
                    style={{backgroundColor: "#1B2534"}}
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['2']}
                    items={items1}
                    onClick={clickMenuItem}
                />
            </Header>
            <Content style={{padding: '0 0px'}}>

                <Layout style={{padding: '0 0', background: colorBgContainer,}}>
                    <Sider style={{background: colorBgContainer}} width={200}>
                        <Menu
                            inlineIndent={20}
                            mode="inline"
                            onClick={onClick}
                            selectedKeys={[selectKey]}
                            defaultOpenKeys={['sub1']}
                            items={resultMenu}
                            style={{
                                minWidth: 0,
                                flex: "auto",
                                width: 200,
                                height:'88vh',
                                overflowY:"scroll",
                            }}>
                        </Menu>
                    </Sider>
                    <Content style={{padding: '10px',overflow: 'hidden'}}>
                        <TabsLayout></TabsLayout>
                    </Content>
                </Layout>
            </Content>
            <ChangePasswordFrom
                closeOpen={() => {
                    setOpen(false);
                }}
                open={open}
            >
            </ChangePasswordFrom>
            <Modal title="个人信信息"
                   onCancel={() => {
                       setIsModalOpen(false);
                   }}
                   open={isModalOpen}
                   footer={null}
                   style={{textAlign: "center"}}
            >
                <p>
                    用户工号：{user.staffNo}
                </p>
                <p>
                    当前账号：{user.acctNo}
                </p>
                <p>
                    用户姓名：{user.staffName}
                </p>
                <p>
                    用户性别：{user.sexTypeCdDesc}
                </p>
                <p>
                    证件类型：{user.certTypeCdDesc}
                </p>
                <p>
                    证件号码：{user.certNo}
                </p>
                <p>
                    归属组织：{user.organIdDesc}
                </p>
                <p>
                    状态：{user.statusCdDesc}
                </p>
            </Modal>
        </Layout>
    );
};

export default CommonIndex;