const baseUrl = "/res/idc/config";
/** 获取列表-参数 */
export interface PageParam {
    configName: string;
    idcId:number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    idcName: string;
    configName: string;
    configItemCd: string;
    configValue: string;
    defaultMark: string;
    statusDesc: string;
    notes: string;
}

//编辑对象
export interface IdcConfigObj {
    id: number;
    idcId: number;
    configName: string;
    configItemCd: string;
    configValue: string;
    defaultMark: string;
    status: string;
    notes: string;
}



export  {baseUrl};

