const baseUrl = "/sub/subscriber";
/** 获取列表-参数 */
export interface PageParam {
    subscriberCode: string;
    acctNo: string;
    phoneNumber:string;
    nickName:string;
    channelName:string;
    beginRegistTime:string
    endRegistTime:string
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subscriberCode: string;
    nickName: string;
    profilePhoto: string;
    phoneNumber: string;
    emailAddr: string;
    webchatNo: string;
    subscriberTypeDesc: string;
    subscriberGradeDesc: string;
    creditGradeDesc: string;
    channelName: string;
    ownerTypeDesc: string;
    ownerObjectId: string;
    registTime: string;
    statusDesc: string;
    notes: string;
}




export  {baseUrl};


