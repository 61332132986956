import React, {useEffect, useState} from 'react';
import {Divider, Form, Input, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/res/server";
import serviceApi from "../../../../apis/res/server";
import "./service.css"
import serverModelApi from "../../../../apis/res/serverModel";
import gatewayApi from "../../../../apis/res/gateway";
import {ipRegex} from "../../../common/const";
import TextArea from "antd/es/input/TextArea";
// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    serverTypeOption: OptionRes[];
    serverModelOption: OptionRes[];
    idcOption: OptionRes[];
    cabinetOption: OptionRes[];
    supplierOption: OptionRes[];
    faultTypeOption: OptionRes[];
    statusOption: OptionRes[];
    instanceGradeCdOptions: OptionRes[];
    gateWayOption: OptionRes[];
    manageNodeOptions: OptionRes[];
}


const ServiceForm: React.FC<StaffFromProps> = ({
                                                   refresh, record, open, closeOpen,
                                                   serverTypeOption, serverModelOption, idcOption, cabinetOption, manageNodeOptions,
                                                   supplierOption, faultTypeOption, statusOption, instanceGradeCdOptions, gateWayOption
                                               }) => {
    const [form] = Form.useForm();
    //机房，机柜信息不可选择
    const [disableIdc, setDisableIdc] = useState(false)
    const [serverModelId, setServerModelId] = useState()
    const [gatewayId, setGatewayId] = useState()
    const [defaultServerType, setDefaultServerType] = useState<OptionRes>()
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && Object.keys(record).length > 0) {
            console.log("record", record)
            form.setFieldsValue({
                ...record
            });
        }
        //设置服务器类型的默认值
        if (serverTypeOption) {
            let optionRe = Array.from(serverTypeOption)[0]
            setDefaultServerType(optionRe);
            console.log("serverTypeOption", optionRe)
            console.log("de", defaultServerType)
        }


    }, [open]);

    //当serverMode改变的时候，重新获取值并填充
    useEffect(() => {
        if (serverModelId) {
            serverModelApi.getById(serverModelId).then((res) => {
                if (res && res.data) {
                    let data = res.data;
                    form.setFieldValue("hostChipType", data.hostChipType);
                    form.setFieldValue("hostOsType", data.hostOsType);
                    form.setFieldValue("hostOsVersion", data.hostOsVersion);
                    form.setFieldValue("hostRamSize", data.hostRamSize);
                    form.setFieldValue("hostRomSize", data.hostRomSize);
                }
            })
        } else {
            form.setFieldValue("hostChipType", "");
            form.setFieldValue("hostOsType", "");
            form.setFieldValue("hostOsVersion", "");
            form.setFieldValue("hostRamSize", null);
            form.setFieldValue("hostRomSize", null);
        }
    }, [serverModelId]);

    useEffect(() => {
        console.log("gateWayId", gatewayId)
        if (gatewayId) {
            gatewayApi.getById(gatewayId).then((res) => {
                if (res && res.data) {
                    let data = res.data;
                    form.setFieldValue("gatewayDomain", data.gatewayDomain);
                    form.setFieldValue("ctccDomain", data.ctccDomain);
                    form.setFieldValue("cmccDomain", data.cmccDomain);
                    form.setFieldValue("cuccDomain", data.cuccDomain);
                    form.setFieldValue("streamNextPort", data.streamNextPort);
                    form.setFieldValue("streamEndPort", data.streamEndPort);
                    form.setFieldValue("controlNextPort", data.controlNextPort);
                    form.setFieldValue("controlEndPort", data.controlEndPort);
                }
            })
        } else {
            form.setFieldValue("gatewayDomain", "");
            form.setFieldValue("ctccDomain", "");
            form.setFieldValue("cmccDomain", "");
            form.setFieldValue("cuccDomain", "");
            form.setFieldValue("streamNextPort", null);
            form.setFieldValue("streamEndPort", null);
            form.setFieldValue("controlNextPort", null);
            form.setFieldValue("controlEndPort", null);
        }
    }, [gatewayId]);

    //处理新增方法
    const handleCreate = () => {
        serviceApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        serviceApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            handleCancel();
            refresh();

        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal

            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            width={1800}
            centered={true}
        >
            <Form
                {...layout}
                form={form}
                initialValues={{"serverTypeCd": "S01"}}
                name="control-hooks"
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <div>
                    <a style={{color: 'black', fontSize: '16px'}}>基础信息</a>
                    <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="serverTypeCd" label="服务器类型"
                                       rules={[{required: true, message: "服务器类型必填"}]}>
                                <Select
                                    style={{width: 175}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={serverTypeOption}
                                />
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="serverCode" label="服务器编码"
                                       rules={[{required: true, message: "服务器编码必填"}]}>
                                <Input style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="supplierId" label="供应商">
                                <Select
                                    style={{width: 175}}
                                    className={"formItemInput"}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={supplierOption}
                                />
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="bmcIp" label="BMC访问IP" rules={[
                                {
                                    pattern: ipRegex,
                                    message: '请输入有效的IP地址',
                                },
                            ]}>
                                <Input style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="bmcPort" label="BMC访问端口"
                                       rules={[{required: true, message: "BMC访问端口必填"}]}>
                                <Input style={{width: 175}}/>
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="serverModelId" label="服务器型号"
                                       rules={[{required: true, message: "服务器型号必填"}]}>
                                <Select
                                    style={{width: 175}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={serverModelOption}
                                    onChange={(value) => {
                                        setServerModelId(value);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="idcId" label="机房"
                                       rules={[{required: true, message: "机房不可为空"}]}>
                                <Select
                                    style={{width: 175}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={idcOption}
                                    disabled={disableIdc}
                                />
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="cabinetId" label="机柜">
                                <Select
                                    style={{width: 175}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={cabinetOption}
                                    disabled={disableIdc}
                                />
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>

                            <Form.Item name="gateway" label="网关"      rules={[{required: true, message: "宿主机起始IP必填"}, {
                                pattern: ipRegex,
                                message: '请输入有效的IP地址',
                            }]}>
                                <Input  style={{width:175}} placeholder='格式:192.168.168.0'/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="subGateway" label="子网掩码"        rules={[{required: true, message: "宿主机起始IP必填"}, {
                                // pattern: ipRegex,
                                message: '请输入有效的IP地址',
                            }]}>
                                <Input  style={{width:175}} placeholder='格式:192.168.168.0/24'/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="notes" label="备注">
                                <TextArea rows={1} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                    </div>
                    {/*服务器型号附带过来的数据*/}
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="hostChipType" label="主控芯片">
                                <Select
                                    style={{width: 175}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    disabled={true}
                                />
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="hostOsType" label="OS类型">
                                <Select
                                    style={{width: 175}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    disabled={true}
                                    filterOption={filterOption}
                                />
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="hostOsVersion" label="OS版本">
                                <Input disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="hostRamSize" label="宿主机运存">
                                <InputNumber disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="hostRomSize" label="宿主机存储">
                                <InputNumber disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>
                <div>
                    <a style={{color: 'black', fontSize: '16px'}}>宿主机配置</a>
                    <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="hostStartIp" label="起始IP"
                                       rules={[{required: true, message: "宿主机起始IP必填"}, {
                                           pattern: ipRegex,
                                           message: '请输入有效的IP地址',
                                       }]}>
                                <Input style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="manageNodeId" label="管理节点"
                                       rules={[{required: true, message: "管理节点不可为空"}]}
                            >
                                <Select
                                    style={{width: 175}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={manageNodeOptions}
                                />
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="hostQuantity" label="数量"
                                       rules={[{required: true, message: "数量"}, {type: 'number', min: 0, max: 1000, message: '宿主机数量大小在0-1000之间'}]}>
                                <InputNumber style={{width: 175}}/>
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                <div>
                    <a style={{color: 'black', fontSize: '16px'}}>云手机配置</a>
                    <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="vmStartIp" label="起始IP"
                                       rules={[{required: true, message: "云手机起始IP必填"}, {
                                           pattern: ipRegex,
                                           message: '请输入有效的IP地址',
                                       }]}>
                                <Input style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="vmQuantity" label="开数"
                                       rules={[{required: true, message: "数量不能为空"}, {type: 'number', min: 0, max: 1000, message: '数量大小在0-1000之间'}]}>
                                <InputNumber style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="instanceGradeCd" label="云手机等级"
                                       rules={[{required: true, message: "云手机等级必填"}]}>
                                <Select
                                    style={{width: 175}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={instanceGradeCdOptions}
                                />
                            </Form.Item>
                        </div>

                    </div>
                </div>

                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                <div>
                    <a style={{color: 'black', fontSize: '16px'}}>推流配置</a>
                    <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="gatewayId" label="推流节点"
                                       rules={[{required: true, message: "推流节点必填"}]}>
                                <Select
                                    style={{width: 175}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={gateWayOption}
                                    onChange={(value) => {
                                        setGatewayId(value);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="gatewayDomain" label="默认域名">
                                <Input disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="ctccDomain" label="电信域名">
                                <Input disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="cmccDomain" label="移动域名">
                                <Input disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '15%', padding: '0 8px'}}>
                            <Form.Item name="cuccDomain" label="联通域名">
                                <Input disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                        <div style={{flexBasis: '20%', padding: '0 8px'}}>
                            <Form.Item name="controlNextPort" label="控制起始端口">
                                <InputNumber disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '20%', padding: '0 8px'}}>
                            <Form.Item name="controlEndPort" label="控制结束端口">
                                <InputNumber disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>

                        <div style={{flexBasis: '20%', padding: '0 8px'}}>
                            <Form.Item name="streamNextPort" label="推流起始端口">
                                <InputNumber disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                        <div style={{flexBasis: '20%', padding: '0 8px'}}>
                            <Form.Item name="streamEndPort" label="推流结束端口">
                                <InputNumber disabled={true} style={{width: 175}}/>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    )
};


interface StaffFromBatchProps {
    refresh: () => void;
    closeOpen: () => void;
    open: boolean;
    serverTypeOption: OptionRes[];
    serverModelOption: OptionRes[];
    idcOption: OptionRes[];
    cabinetOption: OptionRes[];
    supplierOption: OptionRes[];
    faultTypeOption: OptionRes[];
    statusOption: OptionRes[];

}


export default ServiceForm;




