import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import GatewayFrom from "./gatewayForm";
import gatewayApi from "../../../../apis/res/gateway";
import {TableDataType} from "../../../../apis/types/res/gateway";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import propertiesApi from "../../../../apis/sys/properties";
import idcApi from "../../../../apis/res/idc";
import instanceApi from "../../../../apis/res/instance";
import Details from "../../public/details/details";
import GatewayUpdateForm from "./gatewayUpdateForm";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";


const GatewayContent = () => {

    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        queryParams();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<TableDataType[]>([]);
        const [open, setOpen] = useState(false)
        const [updateOpen, setUpdateOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)

    // 下拉列表选项
    const [idcIdOption, setIdcIdOption] = useState<OptionRes[]>([])
    const [gatewayTypeCdOption, setGatewayTypeCdOption] = useState<OptionRes[]>([])
    const [statusCdOption, setStatusCdOption] = useState<OptionRes[]>([])
    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);


    // 查询参数
    function queryParams() {
        idcApi.optinos().then((res) => {
            if (res) {

                setIdcIdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Gateway_Type').then((res) => {
            if (res) {

                setGatewayTypeCdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Gateway_Status').then((res) => {
            if (res) {

                setStatusCdOption(res.data)
            }
        })

    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = gatewayApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        gatewayApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setUpdateOpen(true)
                    setModel('edit')
                    setRowData(record);
                    queryParams()
                }}>
                    编辑
                </Typography.Link>
            },
            width: 100
        },
        {
            title: '所在机房',
            dataIndex: 'idcName',
            key: 'idcName',
            ellipsis:true,

        },
        {
            title: '网关名称',
            dataIndex: 'gatewayName',
            key: 'gatewayName',
            ellipsis:true,
        },
        {
            title: '网关类型',
            dataIndex: 'gatewayTypeCdDesc',
            key: 'gatewayTypeCdDesc',
            ellipsis:true,

        },
        {
            title: '默认域名',
            dataIndex: 'gatewayDomain',
            key: 'gatewayDomain',
            ellipsis:true,
        },
        {
            title: '电信域名',
            dataIndex: 'ctccDomain',
            key: 'ctccDomain',
            ellipsis:true,
        },
        {
            title: '移动域名',
            dataIndex: 'cmccDomain',
            key: 'cmccDomain',
            ellipsis:true,
        },
        {
            title: '联通域名',
            dataIndex: 'cuccDomain',
            key: 'cuccDomain',
            ellipsis:true,
        },
        {
            title: '控制起始端口号',
            dataIndex: 'controlStartPort',
            key: 'controlStartPort',
            ellipsis:true,

        },
        {
            title: '控制结束端口号',
            dataIndex: 'controlEndPort',
            key: 'controlEndPort',
            ellipsis:true,

        },
        {
            title: '控制下个端口号',
            dataIndex: 'controlNextPort',
            key: 'controlNextPort',
            ellipsis:true,

        },
        {
            title: '推流起始端口号',
            dataIndex: 'streamStartPort',
            key: 'streamStartPort',
            ellipsis:true,

        },
        {
            title: '推流结束端口号',
            dataIndex: 'streamEndPort',
            key: 'streamEndPort',
            ellipsis:true,

        },
        {
            title: '推流下个端口号',
            dataIndex: 'streamNextPort',
            key: 'streamNextPort',
            ellipsis:true,
        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis:true,

        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis:true,

        },
    ];

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel('create')
                    queryParams()
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>
                {/*<Popconfirm*/}
                {/*    title="是否确认删除所选项？"*/}
                {/*    onConfirm={() => {*/}
                {/*        if (idList.length === 0) {*/}
                {/*            message.error('删除失败，请选择需要删除的数据');*/}
                {/*        }else {*/}
                {/*            handleDelete();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onCancel={() => {*/}
                {/*        message.error('取消删除');*/}
                {/*    }}*/}
                {/*    okText="是"*/}
                {/*    cancelText="否"*/}
                {/*>*/}
                {/*    <Button type="primary" danger style={{marginRight: '25px'}}>*/}
                {/*        删除*/}
                {/*    </Button>*/}
                {/*</Popconfirm>*/}


                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="gatewayName" label="网关名称">
                        <Input />
                    </Form.Item>
                    <Form.Item name="idcId" label="所在机房">
                        <Select
                            style={{width: 150}}
                            showSearch
                            optionFilterProp="children"
                            allowClear

                            filterOption={filterOption}
                            options={idcIdOption}
                        />
                    </Form.Item>
                    <Form.Item name="gatewayTypeCd" label="网关类型">
                        <Select
                            style={{width: 150}}
                            showSearch
                            optionFilterProp="children"
                            allowClear

                            filterOption={filterOption}
                            options={gatewayTypeCdOption}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={()=>{
                                const body={};
                                gatewayApi.pageData(body,pagination).then((res)=>{
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                    }
                                })
                            }}>重置</Button>                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                   <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增的form表单，子组件*/}
            <GatewayFrom
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                idcIdOption={idcIdOption}
                gatewayTypeCdOption={gatewayTypeCdOption}
                statusCdOption={statusCdOption}
            ></GatewayFrom>
            {/*修改的form表单，子组件*/}
            <GatewayUpdateForm
                refresh={() => {
                    //设置表单不展示
                    setUpdateOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={updateOpen}
                closeOpen={() => {
                    setUpdateOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                idcIdOption={idcIdOption}
                gatewayTypeCdOption={gatewayTypeCdOption}
                statusCdOption={statusCdOption}
            ></GatewayUpdateForm>
            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default GatewayContent;