import React, {useEffect, useState} from 'react';
import {Form, Input, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/app/apk/sysHidden";
import sysHiddenApi from "../../../../apis/app/apk/sysHidden";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface sysHiddenFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    statusOption: OptionRes[];
    open: boolean;
    type: boolean;
}

const SysHiddenForm: React.FC<sysHiddenFormProps> = ({
                                                         refresh,
                                                         record,
                                                         open,
                                                         closeOpen,
                                                         statusOption,
                                                         type,
                                                     }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            ...record
        });
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        sysHiddenApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        sysHiddenApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    return (
        <Modal
            title={type ? "新增商品" : "编辑商品"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >

                <Form.Item name="id" label="主键ID" hidden={true}>
                </Form.Item>
                <Form.Item name="packageName" label="apk包名" rules={[{required: true, message: "apk包名必填"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="appName" label="应用名称" rules={[{required: true, message: "应用名称必填"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}
                           initialValue={"S01"}>
                    <Select
                        style={{width: 200}}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SysHiddenForm;