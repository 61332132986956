const baseUrl = "/mkt/goodsAct";
/** 获取产品申请列表-参数 */
export interface PageParam {
    goodsId:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    actId: string;
    goodsId: number;
    goodsName: string;
    actPrice: number;
    productId: number;
    productName: string;
    pcIcon: string;
    appIcon: string;
    notes: string;

}



export  {baseUrl};

