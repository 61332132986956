import React, {useEffect, useState} from 'react';
import {DatePicker, Divider, Form, Input, InputNumber, Modal, Select} from 'antd';
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/mkt/act/act";
import actApi from "../../../../apis/mkt/act/act";
import TextArea from "antd/es/input/TextArea";
import ActGoodsContent from "./goods/actGoodsContent";
import dayjs from "dayjs";


const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ActFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: number;
    actTypeOption: OptionRes[];


}


const ActForm: React.FC<ActFromProps> = ({
                                             model,
                                             refresh,
                                             record,
                                             open,
                                             closeOpen,

                                             actTypeOption
                                         }) => {


    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && Object.keys(record).length > 0) {

            form.setFieldsValue({
                ...record,
                startTime: dayjs(record.startTime),
                endTime: dayjs(record.endTime),
            })
        }

    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,

            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD') : null,
        };
        actApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    //处理新增的情况
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD') : null,
        };
        actApi.update(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    return (
        <Modal
            title={model === 0 ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            width={1800}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="actName" label="活动名称" rules={[{required: true, message: "活动名称必填"}]}>
                            <Input style={{width: 200}}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>

                        <Form.Item name="actType"  label="活动类型" rules={[{required: true, message: "活动类型必填"}]}>
                            <Select
                                style={{width: 200}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={actTypeOption}
                                disabled={model === 1} // 当 编辑的时候禁用选择框

                            />
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>

                        <Form.Item style={{margin: 5}} name="startTime" label="开始时间"
                                   rules={[{required: true, message: "活动开始时间必填"}]}>
                            <DatePicker
                                showTime
                                placeholder={''}
                                style={{width: 250}}
                                format="YYYY-MM-DD"
                            />


                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item style={{margin: 5}} name="endTime" label="结束时间"
                                   rules={[{required: true, message: "活动结束时间必填"}]}>
                            <DatePicker
                                showTime
                                placeholder={''}
                                style={{width: 250}}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="enableStatus" label="启用状态"
                                   rules={[{required: true, message: "启用状态必填"}]}>
                            <Select
                                style={{width: 200}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={[{value: 0, label: '禁用'}, {value: 1, label: '启用'}]}
                            />

                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="reorder" label="排序字段">
                            <InputNumber min={0} style={{width: 200}}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="notes" label="备注">
                            <TextArea rows={1} style={{width: 300}}/>
                        </Form.Item>
                    </div>
                </div>
            </Form>
            {/*如果是编辑，并且是活动商品类型信息,那么展示活动商品的表单*/}
            {model === 1 && record.actType === 'goods_act' &&
                (
                    <>
                        <Divider></Divider>
                        <h3>活动商品</h3>
                        <div>

                            <ActGoodsContent
                                actId={record.id}
                            ></ActGoodsContent>
                        </div>
                    </>


                )
            }

        </Modal>
    );
};

export default ActForm;




