import {baseUrl, PageParam, TableDataType} from "../../../types/mkt/act/goods/goodsAct";

import {PaginationProps} from "antd";
import {request} from "../../../../utils";
import {PageRes} from "../../../types/common";



const goodsActApi = {
    pageData(body: any, pagination: PaginationProps) {
        let url = baseUrl+"/page";
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.actId) {
                url += 'actId=' + encodeURIComponent(body.actId) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl+"/delete";
        let body = { idList: idList };

        return request.post(url,body);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl+"/create", body)
    },
    //更新数据
    update(body: any) {
        let url = baseUrl+"/update" ;
        return request.post(url, body)
    },

    //禁用数据
    disable(body: any) {
        let url = baseUrl+"/disable" ;
        return request.post(url, body)
    },
    //启用数据
    enable(body: any) {
        let url = baseUrl+"/enable" ;
        return request.post(url, body)
    }


}

export default goodsActApi




