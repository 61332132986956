import {baseUrl, IdcConfigObj, PageParam, TableDataType} from "../types/res/idcConfig";
import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";

const idcConfigApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.configName) {
                url += 'configName=' + encodeURIComponent(body.configName);
            }
            if (body.idcId) {
                url += '&idcId=' + body.idcId;
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询指定信息
    getById(id: Number) {
        let url = baseUrl + '/id/' + id;
        return request.get<IdcConfigObj>(url)
    }

}

export default idcConfigApi



