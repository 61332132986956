import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form, message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
} from 'antd';
import {ColumnsType} from "antd/es/table";
import IptablesFrom from "./iptablesForm";
import iptablesApi from "../../../../apis/res/iptables";
import {TableDataType} from "../../../../apis/types/res/iptables";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import propertiesApi from "../../../../apis/sys/properties";
import gatewayApi from "../../../../apis/res/gateway";
import instanceApi from "../../../../apis/res/instance";
import Details from "../../public/details/details";


const IptablesContent = () => {

    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        queryParams();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
        const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)

    // 下拉列表选项
    const [gatewayIdOption, setGatewayIdOption] = useState<OptionRes[]>([])
    const [instanceOption, setInstanceOption] = useState<OptionRes[]>([])
    const [statusCdOption, setStatusCdOption] = useState<OptionRes[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);


    // 查询参数
    function queryParams() {
        gatewayApi.optinos().then((res) => {
            if (res) {

                setGatewayIdOption(res.data)
            }
        })
        instanceApi.optinos().then((res) => {
            if (res) {

                setInstanceOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Status').then((res) => {
            if (res) {

                setStatusCdOption(res.data)
            }
        })

    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = iptablesApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        iptablesApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        iptablesApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     render: (_: any, record: TableDataType) => {
        //         return <Typography.Link onClick={() => {
        //             setOpen(true)
        //             setModel('edit')
        //             setRowData(record);
        //             queryParams()
        //         }}>
        //             编辑
        //         </Typography.Link>
        //     },
        // },
        {
            title: '网关',
            dataIndex: 'gatewayName',
            key: 'gatewayName',
            ellipsis:true,

        },
        {
            title: '云手机TCP映射外部端口',
            dataIndex: 'externalTcpPort',
            key: 'externalTcpPort',
            ellipsis:true,

        },
        {
            title: '云手机UDP映射外部端口',
            dataIndex: 'externalUdpPort',
            key: 'externalUdpPort',
            ellipsis:true,

        },
        {
            title: '云手机编码',
            dataIndex: 'instanceCode',
            key: 'instanceCode',
            ellipsis:true,

        },
        {
            title: '宿主机IP',
            dataIndex: 'hostIp',
            key: 'hostIp',
            ellipsis:true,

        },
        {
            title: '云手机TCP端口',
            dataIndex: 'tcpPort',
            key: 'tcpPort',
            ellipsis:true,

        },
        {
            title: '云手机UDP端口',
            dataIndex: 'udpPort',
            key: 'udpPort',
            ellipsis:true,

        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis:true,

        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis:true,

        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*<Button type="primary" ghost onClick={() => {*/}
                {/*    setOpen(true)*/}
                {/*    setModel('create')*/}
                {/*    queryParams()*/}
                {/*}} style={{marginRight: '25px'}}>*/}
                {/*    新增*/}
                {/*</Button>*/}
                {/*<Popconfirm*/}
                {/*    title="是否确认删除所选项？"*/}
                {/*    onConfirm={() => {*/}
                {/*        if (idList.length === 0) {*/}
                {/*            message.error('删除失败，请选择需要删除的数据');*/}
                {/*        }else {*/}
                {/*            handleDelete();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onCancel={() => {*/}
                {/*        message.error('取消删除');*/}
                {/*    }}*/}
                {/*    okText="是"*/}
                {/*    cancelText="否"*/}
                {/*>*/}
                {/*    <Button type="primary" danger style={{marginRight: '25px'}}>*/}
                {/*        删除*/}
                {/*    </Button>*/}
                {/*</Popconfirm>*/}
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >

                    <Form.Item name="instanceId" label="云手机编号">
                        <Select
                            style={{width: 150}}
                            showSearch
                            optionFilterProp="children"
                            allowClear

                            filterOption={filterOption}
                            options={instanceOption}
                        />
                    </Form.Item>
                    <Form.Item name="gatewayId" label="网关">
                        <Select
                            style={{width: 150}}
                            showSearch
                            optionFilterProp="children"
                            allowClear

                            filterOption={filterOption}
                            options={gatewayIdOption}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={()=>{
                                const body={};
                                iptablesApi.pageData(body,pagination).then((res)=>{
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                    }
                                })
                            }}>重置</Button>                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                   <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}}  dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <IptablesFrom
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                gatewayIdOption={gatewayIdOption}
                instanceOption={instanceOption}
                statusCdOption={statusCdOption}
            ></IptablesFrom>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default IptablesContent;