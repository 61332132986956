import React, {useEffect, useState} from "react";
import {
    Button,
    ConfigProvider, DatePicker,
    Divider,
    Form, Image, Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../..apis/types/common";
import {TableDataType} from "../../../../../apis/types/mkt/act/goods/goodsAct";

import actGoodsForm from "./actGoodsForm";
import goodsgoodsActApi from "../../../../../apis/mkt/act/goods/goodsAct";
import goodsActApi from "../../../../../apis/mkt/act/goods/goodsAct";
import Details from "../../redeemCode/details";
import ActGoodsForm from "./actGoodsForm";
import actApi from "../../../../../apis/mkt/act/act";
import {imgIp} from "../../../../../components/constantConfig";

const {RangePicker} = DatePicker;

interface ActGoodsContentProps {
    actId: Number


}

const MktGoodsAct: React.FC<ActGoodsContentProps> = ({
                                                         actId

                                                     }) => {


    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [model, setModel] = useState(0)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [actTypeOption, setActTypeOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //进入页面先查询数据
    useEffect(() => {
        console.log("actIdxxx",actId)
        handleQuery();
    }, [actId,pagination]);

    //处理查询
    function handleQuery() {
        let body ={"actId":actId}

        let res = goodsgoodsActApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        goodsActApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }
    //启用
    const enable = () => {
        let body = {"idList": idList}
        goodsActApi.enable(body).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    //禁用
    const disable = () => {
        let body = {"idList": idList}
        goodsActApi.disable(body).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    console.log("operation", record)
                    setRowData(record);
                    setModel(1)
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '产品名称',
            dataIndex: 'productName',
            key: 'productName',
        },

        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
        },
        {
            title: '活动价',
            dataIndex: 'actPrice',
            key: 'actPrice',
        }, {
            title: 'pc应用角标',
            dataIndex: 'pcIcon',
            key: 'pcIcon',
            render: (pictureUrl: string) => {
                const url = imgIp + pictureUrl;
                return <Image src={url} width={100}/>
            },
        },
        {
            title: 'app应用角标',
            dataIndex: 'appIcon',
            key: 'appIcon',
            render: (pictureUrl: string) => {
                const url = imgIp + pictureUrl;
                return <Image src={url} width={100}/>
            },
        },
        {
            title: '启用标识',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);

        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel(0)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Button type="primary" ghost onClick={() => {
                    if (idList.length === 0) {
                        message.error('启用失败，请选择需要启用的数据');
                    } else {
                        enable();
                    }
                }} style={{marginRight: '25px'}}>
                    启用
                </Button>
                <Button type="primary" danger onClick={() => {
                    if (idList.length === 0) {
                        message.error('禁用失败，请选择需要禁用的数据');
                    } else {
                        disable();
                    }
                }} style={{marginRight: '25px'}}>
                    禁用
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        } else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            {/*<Button htmlType="reset" onClick={() => {*/}
                            {/*    const body = {};*/}
                            {/*    goodsgoodsActApi.pageData(body, pagination).then((res) => {*/}
                            {/*        if (res) {*/}
                            {/*            setList(res.data.records)*/}
                            {/*            setTotal(res.data.total)*/}
                            {/*        }*/}
                            {/*    })*/}
                            {/*}}>重置</Button>*/}
                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{textAlign:"right"}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <ActGoodsForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                actId={actId}
            ></ActGoodsForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)

                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default MktGoodsAct;