import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import IdcFrom from "./IdcFrom";
import idcApi from "../../../../apis/res/idc";
import {TableDataType} from "../../../../apis/types/res/idc";
import supplierApi from "../../../../apis/res/supplier";
import {OptionRes} from "../../../../apis/types/common";
import areaApi from "../../../../apis/pub/area";
import Details from "../../public/details/details";


const Idc = () => {

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //进入页面先查询数据
    useEffect(() => {
        getDictList();
    }, []);


    async function getDictList() {
        propertiesApi.optinos('DM_Res_Released_Status').then((res) => {
            if (res) {

                setReleasedStatus(res.data)
            }
        })
        propertiesApi.optinos('DM_Idc_Type').then((res) => {
            if (res) {

                setIdcTypes(res.data)
            }
        })
        propertiesApi.optinos('DM_Status').then((res) => {
            if (res) {

                setStatusCds(res.data)
            }
        })
        supplierApi.optinos().then((result) => {
            if (result) {

                setSuppliers(result.data)
            }
        })
        areaApi.optinos().then((result) => {
            if (result) {

                setAreaOptions(result.data)
            }
        })
    }


    const [model, setModel] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [rowData1, setRowData1] = useState<TableDataType>({} as TableDataType)
    const [idcType, setIdcType] = useState<boolean>(false)

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = idcApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })

    }


    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        idcApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }
    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                    setModel('edit')
                    setIdcType(false);
                }}>
                    编辑
                </Typography.Link>
            },
            ellipsis: true,
            width: 80
        },
        {
            title: '机房名称',
            dataIndex: 'idcName',
            key: 'idcName',
            ellipsis: true,
            width: 150
        },
        {
            title: '机房编码',
            dataIndex: 'idcCode',
            key: 'idcCode',
            ellipsis: true,
            width: 150
        },
        {
            title: '所在区域',
            dataIndex: 'areaIdDesc',
            key: 'areaIdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '机房地址',
            dataIndex: 'idcAddr',
            key: 'idcAddr',
            ellipsis: true,
            width: 200
        },
        {
            title: '机房类型',
            dataIndex: 'idcTypeCdDesc',
            key: 'idcTypeCdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '供应商',
            dataIndex: 'supplierIdDesc',
            key: 'supplierIdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '释放状态',
            dataIndex: 'releasedStatusDesc',
            key: 'releasedStatusDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '状态',
            dataIndex: 'statusCdDesc',
            key: 'statusCdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
            width: 200
        },
    ];

    const [releasedStatus, setReleasedStatus] = useState<OptionRes[]>([]);
    const [idcTypeCds, setIdcTypes] = useState<OptionRes[]>([]);
    const [idcTypeCd, setIdcTypeCd] = useState(null);
    const [statusCds, setStatusCds] = useState<OptionRes[]>([]);
    const [suppliers, setSuppliers] = useState<OptionRes[]>([]);
    const [areaOptions, setAreaOptions] = useState<OptionRes[]>([]);
    const [areaCd, setAreaCd] = useState(null);

    //获取要查询的表单
    const [form] = Form.useForm();

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setIdcType(true);
                    setModel('create')
                    setRowData({} as TableDataType)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>
                {/*<Popconfirm*/}
                {/*    title="是否确认删除所选项？"*/}
                {/*    onConfirm={confirm}*/}
                {/*    onCancel={cancel}*/}
                {/*    okText="是"*/}
                {/*    cancelText="否"*/}
                {/*>*/}
                {/*    <Button type="primary" danger style={{marginRight: '25px'}}>*/}
                {/*        删除*/}
                {/*    </Button>*/}
                {/*</Popconfirm>*/}

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="releasedStatus" label="释放状态">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={releasedStatus}
                        />
                    </Form.Item>
                    <Form.Item name="idcName" label="机房名称">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="idcCode" label="机房编码">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="idcTypeCd" label="机房类型">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            options={idcTypeCds}
                            onChange={(e) => setIdcTypeCd(e)}
                        />
                    </Form.Item>
                    <Form.Item name="areaId" label="所在区域">
                        <Select
                            showSearch
                            allowClear={true}
                            style={{width: 120}}
                            filterOption={filterOption}
                            options={areaOptions}
                            onChange={(e) => setAreaCd(e)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                idcApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData1(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}}
                    dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <IdcFrom
                refresh={() => {
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                    setModel('')
                }}
                idcTypeCds={idcTypeCds}
                model={model}
                releasedStatusOp={releasedStatus}
                statusCds={statusCds}
                suppliers={suppliers}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                areaOptions={areaOptions}
                type={idcType}></IdcFrom>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData1}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default Idc;